import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {debounce, omit} from 'lodash'
import {AdvancedFilterTypes, FilterModel} from '../../models/FilterModel'
import {SortValue} from '../tables/SortValue'
import {useOnMount} from './useOnMount'

const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export interface UseFilterStateData {
  filter: FilterModel
  setPageNumber: (page: number) => void
  setPageSize: (size: number) => void
  setSearch: (keyword: string) => void
  setSearchCode: (code: string) => void
  setSearchEmail: (email: string) => void
  setSortColumn: (sort: SortValue) => void
  setManualSearch: (code: string, email: string) => void
  pageNumber: number
  pageSize: number
  search: string
  searchCode: string
  searchEmail: string
  sortColumn?: SortValue
  advancedFilters: Partial<Record<string, AdvancedFilterTypes>>
  getAdvancedFilterValue: (key: string) => AdvancedFilterTypes | undefined
  setAdvancedFilterValue: (key: string, value: AdvancedFilterTypes) => void
  setAdvancedFilterValues: (values: Partial<Record<string, AdvancedFilterTypes>>) => void
  clearAdvancedFilterValue: (key: string | string[]) => void
  clearFilters: () => void
  hasFilters: boolean
  groupBy?: string
  setGroupBy: (value?: string) => void
}

export interface UseFilterStateOptions {
  debounceDelay?: number
  initialFilters?: FilterModel
  filters?: FilterModel
  filterOnMount?: boolean
}

export const useFilterState = (
  onFilter: (filter: FilterModel) => void,
  options: UseFilterStateOptions = {}
): UseFilterStateData => {
  const {debounceDelay, initialFilters, filters} = options
  const [sortColumn, setSortColumn] = useState<SortValue | undefined>(
    getInitialSortColumn(initialFilters)
  )
  const [clearSearch, setClearSearch] = useState(false)
  const [pageNumber, setPageNumber] = useState(initialFilters?.page || 1)
  const [pageSize, setPageSize] = useState(initialFilters?.limit || 10)
  const [search, setSearch] = useState<string>(
    typeof initialFilters?.filters?.search === 'string' ? initialFilters?.filters?.search : ''
  )
  const [searchCode, setSearchCode] = useState<string>(
    typeof initialFilters?.filters?.code === 'string' ? initialFilters?.filters?.code : ''
  )
  const [searchEmail, setSearchEmail] = useState<string>(
    typeof initialFilters?.filters?.email === 'string' ? initialFilters?.filters?.email : ''
  )
  const [advancedFilters, setAdvancedFilters] = useState<
    Partial<Record<string, AdvancedFilterTypes>>
  >(omit(initialFilters?.filters || {}, 'search'))

  const previousSearchCode = usePrevious(searchCode)
  const previousSearchEmail = usePrevious(searchEmail)

  useEffect(() => {
    if (previousSearchCode !== searchCode || previousSearchEmail !== searchEmail || clearSearch) {
      setPageNumber(1)
    }

  }, [searchCode, searchEmail, previousSearchCode, previousSearchEmail, clearSearch])

  const debouncedOnFilter = useMemo(() => {
    return debounce(onFilter, debounceDelay === undefined ? 500 : debounceDelay)
  }, [onFilter, debounceDelay])

  const stateFilters = useMemo(() => {
    const newFilter: FilterModel = {
      page: pageNumber,
      limit: pageSize,
      filters: {
        ...advancedFilters,
        search: search || undefined,
        code: searchCode || undefined,
        email: searchEmail || undefined,
      },
    };
  
    if (sortColumn) {
      newFilter.sortField = sortColumn[0]
      newFilter.sortOrder = sortColumn[1] ? 'ASC' : 'DESC'
    }
  
    return newFilter
  }, [pageNumber, pageSize, sortColumn, search, searchCode, searchEmail, advancedFilters])

  const getAdvancedFilterValue = useCallback(
    (key: string) => {
      if (filters) {
        return filters?.filters?.[key]
      }
      return advancedFilters[key]
    },
    [advancedFilters, filters]
  )

  const setAdvancedFilterValue = useCallback(
    (key: string, value: AdvancedFilterTypes) => {
      if (filters) {
        const newFilters = {
          ...filters,
          filters: {
            ...filters?.filters,
            [key]: value,
          },
        }
        onFilter(newFilters)
      } else {
        setAdvancedFilters((previousValue) => {
          const newValue = {...previousValue}
          newValue[key] = value
          return newValue
        })
      }
    },
    [filters, onFilter]
  )

  const setAdvancedFilterValues = useCallback(
    (values: Partial<Record<string, AdvancedFilterTypes>>) => {
      if (filters) {
        const newValue = {
          ...filters,
          filters: {
            ...filters.filters,
            ...values,
          },
        }
        Object.keys(newValue.filters).forEach((key) => {
          if (newValue.filters[key] === undefined) {
            delete newValue.filters[key]
          }
        })
        onFilter(newValue)
      } else {
        const newValue = {...advancedFilters, ...values}
        setAdvancedFilters(newValue)
      }
    },
    [advancedFilters, filters, onFilter]
  )

  
  const clearAdvancedFilterValue = useCallback(
    (key: string | string[]) => {
      if (filters) {
        const newValue = {...filters, filters: {...filters.filters}}

        if (typeof key === 'string') {
          delete newValue.filters[key]
        } else {
          key.forEach((key) => {
            delete newValue.filters[key]
          })
        }
        onFilter?.(newValue)
      } else {
        setAdvancedFilters((previousValue) => {
          const newValue = {...previousValue}

          if (typeof key === 'string') {
            delete newValue[key]
          } else {
            key.forEach((key) => {
              delete newValue[key]
            })
          }

          return newValue
        })
      }
    },
    [filters, onFilter]
  )

  const hasFilters = useMemo(() => {
    const initialAdvancedFilters = omit(initialFilters?.filters || {}, ['search', 'code', 'email'])
  
    if (filters) {
      return (
        Boolean(filters.filters?.search) ||
        Boolean(filters.filters?.code) ||
        Boolean(filters.filters?.email) ||
        hasAdvancedFilterChanged(initialAdvancedFilters, omit(filters?.filters, ['search', 'code', 'email']))
      )
    } else {
      return (
        Boolean(search) ||
        Boolean(searchCode) ||
        Boolean(searchEmail) ||
        hasAdvancedFilterChanged(initialAdvancedFilters, omit(advancedFilters, ['search', 'code', 'email']))
      )
    }
  }, [advancedFilters, filters, initialFilters?.filters, search, searchCode, searchEmail])
  

  const handleSetPageNumber = useCallback(
    (page: number, isClear?: boolean) => {
      if (filters) {
        onFilter({
          ...filters,
          page,
        })
        setPageNumber(page)
      } else {
        setPageNumber(page)
      }
    },
    [filters, onFilter]
  )

  const clearFilters = useCallback(() => {
    if (filters) {
      const clearedFilters: FilterModel = {
        ...filters,
        page: 1, 
        filters: omit(filters.filters, ['search', 'code', 'email']), 
      }
      setClearSearch(true)
      setSearch('')
      setSearchCode('')
      setSearchEmail('')
      setAdvancedFilters({})
      onFilter(clearedFilters)
    } else {
      setSearch('')
      setSearchCode('')
      setSearchEmail('')
      setAdvancedFilters({})
    }
  }, [filters, onFilter])
  

  const handleSetPageSize = useCallback(
    (pageSize: number) => {
      if (filters) {
        onFilter({
          ...filters,
          limit: pageSize,
        })
      } else {
        setPageSize(pageSize)
      }
    },
    [filters, onFilter]
  )

  const handleSearch = useCallback(
    (search: string) => {
      if (filters) {
        onFilter({
          ...filters,
          filters: {
            ...filters?.filters,
            search,

          },
        })
      } else {
        setSearch(search)
      }
    },
    [filters, onFilter]
  )
  const handleSearchCode = useCallback(
    (code: string) => {
      if (filters) {
        onFilter({
          ...filters,
          filters: {
            ...filters?.filters,
            code: code,

          },
        })
      } else {
        setSearchCode(code)
      }

    },
    [filters, onFilter]
  )

  const handleSearchEmail = useCallback(
    (email: string) => {
      if (filters) {
        onFilter({
          ...filters,
          filters: {
            ...filters?.filters,
            customerEmail: email,

          },
        })
      } else {
        setSearchEmail(email)
      }
    },
    [filters, onFilter]
  )

  const handleSetSortColumn = useCallback(
    (sort: SortValue) => {
      if (filters) {
        onFilter({
          ...filters,
          sortField: sort[0],
          sortOrder: sort[1] ? 'ASC' : 'DESC',
        })
      } else {
        setSortColumn(sort)
      }
    },
    [filters, onFilter]
  )

  const handleManual = useCallback(
    (code: string, email: string) => {
      if (filters) {
        if (code && email){
          onFilter({
            ...filters,
            filters: {
              ...filters?.filters,
              customerEmail: email,
              code: code,
            },
            page: 1
          })
        }
        else {
          onFilter({
            ...filters,
            filters: {
              ...filters?.filters,
              customerEmail: email,
              code: code,
  
            },
          })
        }
      
      } else {
        setSearchEmail(email)
      }
    },
    [filters, onFilter]
  )


  const sortColumnValue = useMemo((): SortValue | undefined => {
    if (filters) {
      if (filters.sortField && filters.sortOrder) {
        return [filters.sortField, filters.sortOrder === 'ASC']
      }
      return
    }
    return sortColumn
  }, [filters, sortColumn])

  const advancedFiltersMemo = useMemo(() => {
    if (filters?.filters) {
      return omit(filters.filters, 'search')
    }
    return advancedFilters
  }, [advancedFilters, filters?.filters])

  const setGroupBy = useCallback(
    (value: string | undefined) => {
      onFilter({
        ...filters,
        groupBy: value,
      })
    },
    [filters, onFilter]
  )

  useEffect(() => {
    if (!filters) {
      debouncedOnFilter(stateFilters)
    }
  }, [debouncedOnFilter, stateFilters, filters])

  useOnMount(() => {
    if (filters && options.filterOnMount) {
      onFilter(filters)
    }
  })

  return {
    hasFilters,
    filter: stateFilters,
    setPageNumber: handleSetPageNumber,
    setPageSize: handleSetPageSize,
    setSearch: handleSearch,
    setSearchCode: handleSearchCode,
    setSearchEmail: handleSearchEmail,
    setSortColumn: handleSetSortColumn,
    advancedFilters: advancedFiltersMemo,
    pageNumber,
    pageSize,
    search: filters ? String(filters.filters?.search || '') : search,
    searchCode: filters ? String(filters.filters?.code || '') : searchCode,
    searchEmail: filters ? String(filters.filters?.email || '') : searchEmail,
    sortColumn: sortColumnValue,
    getAdvancedFilterValue,
    setAdvancedFilterValue,
    setAdvancedFilterValues,
    clearAdvancedFilterValue,
    clearFilters,
    groupBy: filters?.groupBy,
    setGroupBy,
    setManualSearch: handleManual,
  }
}

const getInitialSortColumn = (filter?: FilterModel): SortValue | undefined => {
  if (filter?.sortField && filter?.sortOrder) {
    return [filter.sortField, filter.sortOrder === 'ASC' ? true : false]
  }
}

const hasAdvancedFilterChanged = (
  previous: Required<FilterModel>['filters'],
  current: Required<FilterModel>['filters']
): boolean => {
  return compareEquality(previous, current) || compareEquality(current, previous)
}

// Returns true if b has all fields of a
const compareEquality = (
  a: Required<FilterModel>['filters'],
  b: Required<FilterModel>['filters']
) => {
  return Object.entries(a).some(([key, aValue]) => {
    if (key in b) {
      const bValue = b[key]
      if (typeof bValue === 'string' || typeof bValue === 'boolean') {
        return bValue !== aValue
      } else if (Array.isArray(bValue) && Array.isArray(aValue)) {
        return bValue.length !== aValue.length || bValue.some((value) => !aValue.includes(value))
      }
    }
    return true
  })
}
