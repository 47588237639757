/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useState} from 'react'
import {FilterModel} from '../../../models/FilterModel'
import {GlobalSearchModel, GroupedSearchModel} from '../../../models/GlobalSearchModel'
import {useBooleanState} from '../../hooks/useBooleanState'
import {useFilterState} from '../../hooks/useFilterState'
import {useOnChange} from '../../hooks/useOnChange'
import {useSafeStateUpdate} from '../../hooks/useSafeStateUpdate'
import {TextInput} from '../../inputs'
import {MetronicIconButton} from '../../inputs/MetronicIconButton'
import {GroupedTableData, Table, TableProps} from '../Table'
import {TableColumnOptions} from '../TableColumn'
import {TableSelectionCountLabel} from '../TableSelectionCountLabel'
import {TableFilterStateContext} from '../useTableFilterState'
import {PopupFilterButton} from './PopupFilterButton/PopupFilterButton'
import {Button} from '../../inputs/Button'
import {MetronicIcon} from '../../inputs/MetronicIcon'
import PaginationHelper from '../../extras/PaginationHelper'
import {INITIAL_TABLE_PAGE_SIZE} from '../FilterTable'

type FilterTableTableProps<T> = Pick<
  TableProps<T>,
  | 'selection'
  | 'onSelectionChange'
  | 'rightToolbar'
  | 'idExtractor'
  | 'actions'
  | 'hiddenColumns'
  | 'onHiddenColumnsChange'
  | 'title'
  | 'hideSelectAll'
  | 'actions'
  | 'expandedGroups'
  | 'onExpandedGroupsChange'
  | 'grouping'
  | 'classes'
>

export type ControlledFilterTableColumnOptions<T> = TableColumnOptions<T>

export interface ControlledFilterTableProps<T> extends FilterTableTableProps<T> {
  onFilter: (filter: FilterModel) => void
  searchResults?: GlobalSearchModel<T> | GroupedSearchModel<T>
  filters: FilterModel
  noPagination?: boolean
  selectionAction?: ReactNode
  leftToolbar?: ReactNode
  columns: ControlledFilterTableColumnOptions<T>[]
  className?: string
  initialFilters?: FilterModel
  advancedFilters?: ReactNode
  filterOnMount?: boolean
  paginationClassName?: string
  cornersClassName?: string
  customWrapper?: string
  loading?: boolean
  isTrp?: boolean
}

export const ControlledFilterTable = <T,>({
  onFilter,
  noPagination,
  leftToolbar,
  selectionAction,
  selection,
  onSelectionChange,
  columns,
  rightToolbar,
  className,
  initialFilters,
  advancedFilters: advancedFiltersNode,
  searchResults,
  filters,
  filterOnMount,
  onExpandedGroupsChange,
  expandedGroups,
  paginationClassName,
  cornersClassName,
  customWrapper,
  loading = false,
  isTrp = false,
  ...tableProps
}: ControlledFilterTableProps<T>) => {
  const [tempCode, setTempCode] = useState('')
  const [tempEmail, setTempEmail] = useState('')
  const [hidePrev, setHidePrev] = useState(true)
  const [hideNext, setHideNext] = useState(false)


  const safeUpdate = useSafeStateUpdate()
  const {
    state: isPopupOpen,
    enableState: openPopup,
    disableState: closePopup,
  } = useBooleanState(false)

  useOnChange(searchResults?.data, () => {
    safeUpdate(() => onSelectionChange && onSelectionChange([]))
  })

  const filterState = useFilterState(onFilter, {initialFilters, filters, filterOnMount})

  const {
    sortColumn,
    setSortColumn,
    pageNumber,
    setPageSize,
    setPageNumber,
    setSearch,
    search,
    clearFilters,
    hasFilters,
    setManualSearch,
  } = filterState

  const handleNextPage = useCallback(() => {
    const nextPage = pageNumber + 1
    setPageNumber(nextPage) 

    if (searchResults && Array.isArray(searchResults.data) && searchResults.data.length > 0) {
      setHidePrev(false)
    } else {
      setHideNext(true) 
    }
  }, [pageNumber, searchResults, setPageNumber])

  const handlePrevPage = useCallback(() => {
    if (pageNumber > 1) {
      const prevPage = pageNumber - 1
      setPageNumber(prevPage)

      if (prevPage === 1) {
        setHidePrev(true)
      }
    }
    setHideNext(false)
  }, [pageNumber, setPageNumber])

  
  useEffect(() => {
    setHidePrev(pageNumber === 1)
    setHideNext(
      !searchResults?.data || 
      (Array.isArray(searchResults.data) && searchResults.data.length < 100) 
    )
  }, [searchResults, pageNumber])
  

  const handleChangePageNumber = useCallback(
    (newPageNumber: number) => {
      setPageNumber(newPageNumber)
      onSelectionChange && onSelectionChange([])
    },
    [onSelectionChange, setPageNumber]
  )
  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value)
    },
    [setSearch]
  )
  const handleSearchCode = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTempCode(e.target.value)
  }, [])
  const handleSearchEmail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTempEmail(e.target.value)
    },
    [setTempEmail]
  )

  const resetSearch = useCallback(() => {

    setTempCode('')
    setTempEmail('')
    setPageNumber(1)

    setManualSearch('', '')
    const clearedFilters = {
      ...filters,
      filters: {
        ...filters.filters,
        code: null,
        customerEmail: null,
      },
      page: 1
    }

    onFilter(clearedFilters)
  }, [clearFilters])



  const handleManualSearch = useCallback(() => {
    setManualSearch(tempCode, tempEmail)
  
    const shouldResetPage =
      tempCode !== filters.filters?.code || tempEmail !== filters.filters?.customerEmail
  
    const updatedFilters = {
      ...filters,
      filters: {
        ...filters.filters,
        code: tempCode,
        customerEmail: tempEmail,
      },
      page: shouldResetPage ? 1 : filters.page,
    }
  
    onFilter(updatedFilters)
  }, [tempCode, tempEmail, onFilter, filters])

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        handleManualSearch()
      }
    },
    [handleManualSearch]
  )

  const searchAction = useMemo(() => {
    if (isTrp) {
      return (
        <div className='w-100'>
          <div className='table-left-toolbar'>
            <TextInput
              className='filter-table-search-input'
              noMargin
              placeholder='Search Code'
              value={tempCode}
              onChange={handleSearchCode}
              onKeyDown={loading ? undefined : handleKeyPress} 
            />

            {leftToolbar}
            <TextInput
              className='filter-table-search-input'
              noMargin
              placeholder='Search Email'
              value={tempEmail}
              onChange={handleSearchEmail}
              onKeyDown={loading ? undefined : handleKeyPress} 
            />
            {hasFilters && (
              <MetronicIconButton
                className='rs-clear-filter'
                iconType='Code'
                iconName='Stop'
                activeColor='danger'
                size='md'
                onClick={resetSearch}
                tooltip='Clear Filters'
              />
            )}
            <Button
              uppercase={false}
              variant='primary'
              onClick={handleManualSearch}
              disabled={(!tempCode && !tempEmail) || loading}
           
            >
              <MetronicIcon iconType='General' iconName='Magnifier' /> Search
            </Button>
          </div>
        </div>
      )
    } else
      return (
        <div className='w-100'>
          <div className='table-left-toolbar'>
            <TextInput
              className='filter-table-search-input'
              noMargin
              placeholder='Search'
              value={search}
              onChange={handleSearchChange}
            />
            {hasFilters && (
              <MetronicIconButton
                className='rs-clear-filter'
                iconType='Code'
                iconName='Stop'
                activeColor='danger'
                size='md'
                onClick={clearFilters}
                tooltip='Clear Filters'
              />
            )}
            {leftToolbar}
          </div>
        </div>
      )
  }, [
    isTrp,
    search,
    handleSearchChange,
    hasFilters,
    clearFilters,
    leftToolbar,
    tempCode,
    handleSearchCode,
    tempEmail,
    handleSearchEmail,
    resetSearch,
    handleManualSearch,
  ])

  const bodyActions = useMemo(() => {
    return (
      <div className='mt-3'>
        <TableSelectionCountLabel count={selection?.length} />
        {selectionAction && <div className='table-left-toolbar'>{selectionAction}</div>}
      </div>
    )
  }, [selection?.length, selectionAction])

  const hasAdvancedFiltersValue = useMemo(() => {
    return Boolean(filters?.filters && Object.entries(filters.filters).length)
  }, [filters.filters])


  const filterButton = useMemo(() => {
    const hasAdvancedFilterInputs = Boolean(advancedFiltersNode)
    if (hasAdvancedFilterInputs) {
      return (
        <PopupFilterButton
          isOpen={isPopupOpen}
          onClose={closePopup}
          onOpen={openPopup}
          hasAdvancedFilters={hasAdvancedFiltersValue}
        >
          {advancedFiltersNode}
        </PopupFilterButton>
      )
    }
    return null
  }, [advancedFiltersNode, closePopup, hasAdvancedFiltersValue, isPopupOpen, openPopup])

  const groupedTableData = useMemo(() => {
    if (searchResults) {
      if (!Array.isArray(searchResults.data)) {
        const groupBy = filters?.groupBy
        if (groupBy) {
          return Object.entries(searchResults.data).map<GroupedTableData<T>>(([key, value]) => ({
            key,
            data: value,
            field: groupBy,
            label: key,
          }))
        }
      }
    }
  }, [filters?.groupBy, searchResults])

  const tableData = useMemo(() => {
    if (Array.isArray(searchResults?.data)) {
      return searchResults?.data
    }
  }, [searchResults?.data])

  useOnChange(searchResults, (previousValue) => {
    if (previousValue?.page !== searchResults?.page || !previousValue) {
      if (searchResults && !Array.isArray(searchResults.data)) {
        const groupKeys = Object.keys(searchResults.data)
        const firstGroupKey = Object.keys(searchResults.data)[0]
        if (
          firstGroupKey &&
          expandedGroups &&
          !groupKeys.some((key) => expandedGroups?.includes(key))
        ) {
          onExpandedGroupsChange?.([firstGroupKey])
        }
      }
    }
  })

  return (
    <TableFilterStateContext.Provider value={filterState}>
      <div className={clsx('position-relative', className)}>
        {cornersClassName && <div className={cornersClassName}></div>}
        <Table
          {...tableProps}
          expandedGroups={expandedGroups}
          onExpandedGroupsChange={onExpandedGroupsChange}
          columns={columns}
          data={tableData}
          groupedData={groupedTableData}
          onSelectionChange={onSelectionChange}
          selection={selection}
          leftToolbar={searchAction}
          sortedColumn={sortColumn}
          onSort={setSortColumn}
          body={bodyActions}
          grouping={filters?.groupBy}
          className={customWrapper}
          loading={loading}
          rightToolbar={
            <>
              {rightToolbar}
              {filterButton}
            </>
          }
        />

        {isTrp ? (
          <>
            <div className='d-flex justify-content-end my-3 mb-4'>
              {!hidePrev && (
                <button type='button' className='btn btn-primary me-2' onClick={handlePrevPage}>
                  PREV
                </button>
              )}
              {!hideNext && (
                <button type='button' className='btn btn-primary' onClick={handleNextPage}>
                  NEXT
                </button>
              )}
            </div>
          </>
        ) : (
          <>
            {!noPagination && (
              <PaginationHelper
                currentPageNumber={searchResults?.page || pageNumber}
                currentPageSize={searchResults?.limit || INITIAL_TABLE_PAGE_SIZE}
                onChangePageNumber={handleChangePageNumber}
                onChangePageSize={setPageSize}
                total={searchResults?.total}
                className={paginationClassName}
              />
            )}
          </>
        )}
      </div>
    </TableFilterStateContext.Provider>
  )
}
